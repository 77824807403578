<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="mb-2">
          <div slot="header">
            <strong>SHOP 검색 </strong>
            <div class="card-header-actions">
              <div class="d-inline-block ml-3">
                <i :class="`icon-arrow-${collapse.side?'right':'left'} pointer`" @click="collapse.side = !collapse.side"></i>
              </div>
            </div>
          </div>

          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-button variant="primary" @click="list" :disabled="busy.list">
                <i class="fa fa-search"></i> 검색
                <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
              </b-button>
            </b-input-group-prepend>
            <b-form-input id="search" type="text" placeholder="" v-model="form.search" @keypress.enter.prevent.stop="list" autocomplete="off"
                          v-focus></b-form-input>
            <b-input-group-append>
              <b-button v-if="$R('DEV')" :variant="query ? 'success' : 'light'" @click="modal.query = true">Query</b-button>
            </b-input-group-append>
          </b-input-group>

          <b-modal title="추가 Query 지정" size="lg" v-model="modal.query" cancel-title="Clear" cancel-variant="light" @cancel="query = ''">
            <h6>shop_type: 'parallel', return_cost: {$gt: 10000}, apply_sale_rate: {$nin: ['', null, 0]}</h6>
            형식으로 입력해주세요. {} 를 제외한 object 형식에 문제가 없다면 쿼리에 추가됩니다.
            <b-textarea v-model="query" rows="10"></b-textarea>
          </b-modal>

          <shop-preset v-model="form.shop"></shop-preset>

          <b-collapse id="collapse" v-model="collapse.detail">
            <form-options class="mt-1" v-model="form" v-bind="{formOptions}"></form-options>
            <div class="fs-12 bold">
              검색 필드
            </div>
            <form-fields ref="fields" v-model="form.fields" :name="$options.name" :customFormFields.sync="customFormFields"
                         v-bind="{formFields, defaultFields}" @enter="list()"></form-fields>
          </b-collapse>

          <div class="mt-2 clearfix">
            <b-button class="" variant="primary" @click="list" :disabled="busy.list">
              검색
              <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
            </b-button>
            <b-button class="ml-1" variant="warning" @click="resetForm()">초기화</b-button>
            <b-button class="ml-1" variant="outline-success" v-b-toggle.collapse>상세검색조건</b-button>
            <div class="pull-right">
              <b-button class="mr-1" variant="outline-success" @click="downAll('xlsx')" :disabled="busy.xlsx">
                해당 검색조건의 샵 XLSX
                <b-spinner class="ml-1" small v-if="busy.xlsx"></b-spinner>
              </b-button>
              <b-button class="" variant="success" @click="addShop">SHOP 추가</b-button>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" v-if="collapse.side">
        <b-card no-body>
          <div slot="header">
            <strong>
              환율정보
              <a href="https://bank.shinhan.com/index.jsp#020501010000" target="_blank">
                <small class="text-muted">시세정보: 신한은행({{ exchangeRealtimeDt }})</small>
              </a>
            </strong>
            <div class="card-header-actions">
              <b-link class="card-header-action btn-minimize" v-b-toggle.exchange_collapse>
                <i :class="`icon-arrow-${collapse.exchange?'up':'down'}`"></i>
              </b-link>
            </div>
          </div>
          <b-collapse id="exchange_collapse" v-model="collapse.exchange">
            <b-card-body>
              <b-row class="text-center">
                <b-col>
                  통화명
                </b-col>
                <b-col>
                  설정된 환율
                </b-col>
                <b-col>
                  전신환 송금
                </b-col>
                <b-col>
                  매매기준율
                </b-col>
              </b-row>
              <template v-for="c in Object.keys(exchangeMap)">
                <div v-if="c !== 'KRW'" class="mb-2 text-center" :key="c">
                  <b-row>
                    <b-col>
                      <b-button variant="primary" block @click="$utils.open(`https://search.naver.com/search.naver?sm=tab_hty.top&where=nexearch&query=${c}`)">
                        {{ c }}
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-form-input class="text-center" type="text" v-model.number="exchangeMap[c]"></b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-input class="text-center" plaintext type="text" :value="(exchangeRealtimeMap[c] || {})['전신환매도환율']"></b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-input class="text-center" plaintext type="text" :value="(exchangeRealtimeMap[c] || {})['매매기준환율']"></b-form-input>
                    </b-col>
                  </b-row>
                </div>
              </template>
              <div class="clearfix">
                <b-button class="mr-1" variant="success" @click="applyExchange">적용하기</b-button>
                <b-button class="ml-1 pull-right" variant="light" @click="$modal.show({title: 'JSON 보기', type: 'json', item: meta.exchange})">JSON</b-button>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body v-if="$R('SHOP_R')">
          <div slot="header">
            <strong><i class="icon-settings"></i> 가격결정요소 설정</strong>
            <div class="card-header-actions">
              <b-link class="card-header-action btn-minimize" v-b-toggle.meta_collapse>
                <i :class="`icon-arrow-${collapse.meta?'up':'down'}`"></i>
              </b-link>
            </div>
          </div>
          <b-collapse id="meta_collapse" v-model="collapse.meta">
            <b-card-body>
              <b-row>
                <b-col cols="4" class="mt-2" v-for="m in priceMeta" :key="m.desc">
                  <small>{{ m.desc }}</small>
                  <b-form-input class="text-center" type="text" v-model.number="m.value" :readonly="!!m.readonly"></b-form-input>
                </b-col>
              </b-row>
              <b-button class="mt-3" variant="success" @click="applyPriceMeta">적용하기</b-button>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>

    <div class="clearfix">
      <div class="pull-left" v-if="$R(['SHOP_W', 'SHOP_X'])">
          <b-button class="mr-1" variant="primary" @click="changeShopUseYN()">사용여부 일괄 변경</b-button>
      </div>
      <div class="pull-right" v-if="$R('SHOP_R')">
        <b-button class="m-1" variant="light" @click="copyShopID">Shop ID 복사</b-button>

        <b-dropdown variant="success" class="m-1" :disabled="busy.xlsxDown">
          <template v-slot:button-content>
            <b-spinner class="mr-2" small v-if="busy.xlsxDown"></b-spinner>
            Xlsx Download
          </template>
          <b-dropdown-item @click="down('raw')">RAW Xlsx</b-dropdown-item>
          <b-dropdown-item @click="down('xlsx')">Xlsx</b-dropdown-item>
          <b-dropdown-item @click="down('txt')">Text</b-dropdown-item>
        </b-dropdown>

        <b-button class="m-1" variant="outline-success" @click="modal.xlsxUpload = true">Xlsx Upload</b-button>
        <xlsx-upload-modal v-model="modal.xlsxUpload" @refreshList="list"></xlsx-upload-modal>
      </div>
    </div>

    <b-modal title="사용여부 일괄변경" size="lg" v-model="modal.shopUseYn" ok-title="변경" cancel-title="닫기" @hide="modalHide">
      <b-alert variant="primary" show>총 {{items.list.filter(e => e.selected).length}}개 Shop의 사용여부를 변경합니다.</b-alert>
      <strong class="bold">사용여부 변경</strong><br/>
      <b-form-radio-group class="col-form-label mb-3" size="sm" v-model="item.useYnselected" :options="[{text: '사용', value: 'y'},{text: '미사용', value: 'n'}]">
      </b-form-radio-group>
      <div v-if="item.useYnselected === 'n'">
        <div class="border-bottom mb-3"></div>
        <b-alert variant="danger" show>미사용 처리 시 해당 Shop의 전체 상품이 미노출 처리되며, 이용 제한 고지 알림톡이 발송됩니다.</b-alert>
          <strong class="bold">미사용 처리 사유</strong><span class="ml-1 text-danger">*</span><br/>
          <b-input-group class="mt-2">
            <b-input-group-prepend>
              <b-form-select v-model="item.use_yn_reason" :options="['이용 제한 조치', '고의적 부당행위 제한', '퇴점 처리 완료', '기타']"
                             @input="() => {if (item.use_yn_reason !== '기타') {
                               item.use_yn_reason_detail = item.use_yn_reason;
                             } else {
                               item.use_yn_reason_detail = '';
                             }}">
              </b-form-select>
            </b-input-group-prepend>
            <b-input v-model="item.use_yn_reason_detail" @input="inputReason" :placeholder="item.use_yn_reason === '기타' ? '기타 사유를 입력해주세요.' : ''"></b-input>
          </b-input-group>
        </div>
    </b-modal>

    <b-modal title="변경사항 확인" size="lg" v-model="modal.shopUseYnReason">
      <div><b-badge variant="light">use_yn</b-badge> "{{item.useYnselected === 'y' ? 'n' : 'y'}}" → "{{item.useYnselected}}"</div>
      <div class="border-bottom mt-3 mb-3"></div>
      <small class="text-muted">아래 Shop은 이미 {{item.useYnselected === 'y' ? '사용' : '미 사용'}}중으로 변경 항목에서 제외됩니다.</small><br/>
      <div><b-badge variant="light">shop_id</b-badge> {{items.list.filter(e => e.selected && e.use_yn === item.useYnselected).map(e => e.shop_id).join(', ') || '-'}}</div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" @click="updateUseYn()">
          저장
        </b-button>
        <b-button size="sm" variant="secondary" @click="() => { cancel(); item.use_yn_reason_detail = ''; item.use_yn_reason = '';}">
          취소
        </b-button>
      </template>
    </b-modal>

    <c-table ref="c-table:list" small :table-data="items.list" :fields="fields.list" :perPage.sync="perPage" :isBusy="busy.list"
             :caption="items.list.length + (hasMore.list ? '+' : '') + ' 개'" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" @get-more="list(true)"
             @show-modal-clicked="showDetail"></c-table>

    <shop-modal v-model="modal.detail" ref="shopModal" v-bind="{priceMap, exchangeMap, modalMode, options, userMap}" @refreshList="list"></shop-modal>

    <b-modal title="Shop 정보 다운로드" size="lg" v-model="modal.download" @hide="formDownReason.resolve(false)">
      <b-alert show variant="info">총 {{items.list.filter(e => e.selected).length}} 건의 정보를 다운로드 합니다</b-alert>
      <div><small>사유</small></div>
      <b-form-select v-model="formDownReason.reason" :options="[
          {text: '주문 관련 업무(취소/환불, 반품 등)', value: 'order'},
          {text: '배송 관련 업무', value: 'deliver'},
          {text: '고객 문의 및 고충처리 관련 업무', value: 'customer'},
          {text: '마케팅 관련 업무(CRM, 온사이트 등)', value: 'marketing'},
          {text: '서비스 기획, 개발 관련 업무', value: 'develop'},
          {text: '정산관련 업무', value: 'settle'},
          {text: '기타', value: 'etc'}
        ]">
      </b-form-select>
      <b-form-input v-if="formDownReason.reason === 'etc'" v-model="formDownReason.reasonEtc" placeholder="기타 사유를 입력해 주세요(30자 제한)." maxlength="30"></b-form-input>
      <template v-slot:modal-footer="{}">
        <b-button variant="outline-secondary" @click="formDownReason.resolve(false)">
          Cancel
        </b-button>
        <b-button variant="success" @click="formDownReason.resolve(true)">
          Ok
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import shopModal from '@/views/shop/ShopModal.vue';
import xlsxUploadModal from '@/views/shop/xlsx/Upload.vue';
import {down} from '@/shared/impexp';
import FormOptions from '../modules/FormOptions';
import FormFields from '../modules/FormFields';
import {formFieldsGen, formOptionsGen} from './formData';
import Vue from "vue";

const COL_DICT = {
  boutique: '부티크',
  parallel: '병행',
  edit_shop: '편집샵',
  balaan: '자사',
  store: '스토어',
  brand: '브랜드',
  etc: '기타',
  abroad: '해외',
  domestic: '국내',
  both: 'BOTH'
};

export default {
  name: 'ShopList',
  title: 'SHOP 정보',
  components: {shopModal, xlsxUploadModal, FormOptions, FormFields},
  data() {
    return {
      COL_DICT,
      teamMap: this.$utils.arr2map(this.$C.TEAMS, 'value'),
      priceMap: {},
      priceMeta: [],
      exchangeMap: {},
      exchangeRealtimeMap: {},
      exchangeRealtimeDt: '',
      categoryMap: {},
      meta: {},
      userMap: {},
      defaultShopObject: {
        dynamicPricingVer: '1.0.0',
        shop_id: '',
        boutique: '',
        use_yn: 'n',
        shop_type: '',
        currency: '',
        country: '',
        logistics: 'direct',
        today_pick_open: true,
        goodsType: 'new',
        addr: {},
        api_order_type: '',
        return_ok: 'y',
        direct_return_yn: 'n',
        stock_manage: 'manual',
        return_cost_type: 'shop',
        exchange_cost_type: 'shop',
        ep_fast_delivery: false,
        is_test_shop: false,
        is_exclude_priceLimit: false,
        cardPromotionEnabled: false,
        partnerGrade: 'normal',
        base_expected_settlement_date: 'confirmation',
        init_display_status: 'view',
        ep_exclude: 'n',
        goodsHealthRating: 'B',
        autoPlaceOrder: false,
        changeToRegistered: 'n',
        brandBlackList: [],
        fetch_type: 'MANUAL',
        mapped_consumer_type: '소비자가',
        mapped_price_type: '판매가',
        mapped_supply_type: '공급가',
        name_open: true,
        orderMade: false,
        categoryWhiteList: ['009', '010'],
      },
      defaultForm: {
        shop: [],
        search: '',
        use_yn: 'y',
        shop_type: 'ALL',
        delivery_type: 'ALL',
        currency: 'ALL',
        is_test_shop: 'ALL',
        is_exclude_priceLimit: 'ALL',
        orderMade: 'ALL',
        consignUsed: 'ALL',
        bPriceExclude: 'ALL',
        cardPromotionEnabled: 'ALL',
        partnerGrade: 'ALL',
        dynamicPricingEnabled: 'ALL',
        logistics: 'ALL',
        return_ok: 'ALL',
        direct_return_yn: 'ALL',
        sync_origin: 'ALL',
        fetch_type: 'ALL',
        name_open: 'ALL',
        expose_goods_link: 'ALL',
        init_display_status: 'ALL',
        goods_name_policy: 'ALL',
        ep_exclude: 'ALL',
        long_image: 'ALL',
        stock_manage: 'ALL',
        goodsType: 'ALL',
        return_cost_type: 'ALL',
        exchange_cost_type: 'ALL',
        ep_fast_delivery: 'ALL',
        pt_field: ['consumer', 'newin', 'sale', 'supply'],
        pt_price: 'ALL',
        pt_delivery: 'ALL',
        pt_krw_price: 'ALL',
        pt_krw_delivery: 'ALL',
        pt_pg: 'ALL',
        rebate_rate_used: 'ALL',
        base_expected_settlement_date: 'ALL',
        sort: 'shop_id:-1',
        sortKey: 'shop_id',
        sortDir: 'desc',
        categoryWhiteList: [],
        // categoryWhiteList: ['009', '010', '011', '020', '030', '040', '050', '060', '070'],

        fields: {},

        limit: 500,
        skip: 0,
      },
      form: {},
      formDownReason: {
        resolve: () => {},
        reason: 'order',
        reasonEtc: '',
      },
      item: {
        useYnselected: 'y',
      },
      item_org: {},
      fields: {
        list: [
          {key: 'selected'},
          {key: 'shop_id', class: 'text-center'},
          {key: 'html1', label: '파트너 <span class="badge badge-light">연동원천</span><br/>파트너주소', style: {maxWidth: '450px'}},
          {key: 'html6', label: '유형<br/>국가<br/>통화', class: 'text-center', style: 'width:45px'},
          {key: 'html3', label: '배송', class: 'text-center', style: 'width:75px'},
          {key: 'html8', label: '현지배송비<br/>배송비원가', class: 'text-center', style: 'width:75px'},
          {key: 'html9', label: '반품가능<br/>국내반품<br/>해외반품', class: 'text-center', style: 'width:56px'},
          {key: 'html4', label: '수수료', class: 'text-center'},
          {key: 'lambda1', label: '가격계산<br/>Dynamic<br/>세금', class: 'text-center'},
          {key: 'lambda3', label: '영업<br/>운영<br/>개발', class: 'text-center', style: 'min-width:42px'},
          {key: 'lambda4', label: '계약일<br/>입점일', class: 'text-center', style: 'width:75px', sortable: true},
          {key: 'show_modal', label: '상세', class: 'text-center', style: 'width:45px'}
        ]
      },
      perPage: 20,
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, listmore: false, detail: false, xlsx: false, xlsxDown: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      modal: {
        detail: false,
        shopUseYn: false,
        shopUseYnReason: false,
        query: false,
        xlsxUpload: false,
        download: false,
      },
      modalMode: 'edit',
      query: '',
      options: {
        shop_type: [
          {text: '부티크', value: 'boutique'},
          {text: '편집샵', value: 'edit_shop'},
          {text: '병행', value: 'parallel'},
          {text: '자사', value: 'balaan'},
          {text: '스토어', value: 'store'},
          {text: '브랜드', value: 'brand'},
          {text: '기타', value: 'etc'},
        ],
        currency: [
          {text: 'KRW', value: 'KRW'},
          {text: 'EUR', value: 'EUR'},
          {text: 'USD', value: 'USD'},
          {text: 'GBP', value: 'GBP'},
          {text: 'CAD', value: 'CAD'},
          {text: 'HKD', value: 'HKD'},
          {text: 'AUD', value: 'AUD'},
        ],
        logistics: [
          {text: 'BALAAN 물류', value: 'balaan'},
          {text: 'Direct 배송', value: 'direct'},
        ],
        delivery_type: [
          {text: '해외', value: 'abroad'},
          {text: '국내', value: 'domestic'},
          {text: 'BOTH', value: 'both'},
        ],
        basePrice: [
          {text: 'Mapped Consumer', value: 'mappedConsumer'},
          {text: 'Mapped Price', value: 'mappedPrice'},
          {text: 'Mapped Supply', value: 'mappedSupply'}
        ],
        yn: [
          {text: 'Y', value: 'y'},
          {text: 'N', value: 'n'}
        ],
        pzn: [
          {text: '+', value: '+'},
          {text: '0', value: '0'},
          {text: '-', value: '-'}
        ],
        casn: [
          {text: 'Consumer', value: 'consumer'},
          {text: 'Avg', value: 'avg'},
          {text: 'Supply', value: 'supply'},
          {text: 'N', value: 'n'}
        ],
        users: [],
        sync_origin: [
          {text: '자동(Feed)', value: 'feed'},
          {text: '파트너센터', value: 'partner'},
          {text: '위즈파스타', value: 'partner_wizfasta'},
          {text: 'EC모니터', value: 'partner_ecm'},
          {text: '사방넷', value: 'partner_sabangnet'},
          {text: '스토어봇', value: 'partner_storebot'},
          {text: '더망고', value: 'partner_themango'},
          {text: '샵플링', value: 'partner_shopling'},
          {text: '이지어드민', value: 'partner_ezadmin'},
          {text: '세원셀릭', value: 'partner_sewonsellic'},
          {text: '샵링커', value: 'partner_shoplinker'},
          {text: '파트너API', value: 'partner_api'},
          {text: '수동(Mapped)', value: 'mapped'},
          {text: '미정', value: 'unknown'},
        ],
        goodsType: [
          {text: '새상품 + 빈티지', value: 'both'},
          {text: '새상품만', value: 'new'},
          {text: '빈티지만', value: 'used'}
        ],
        return_cost_type: [
          {text: 'SHOP 단위', value: 'shop'},
          {text: '상품별', value: 'goods'},
        ],
        is_test_shop: [
          {text: 'N', value: false},
          {text: 'Y', value: true}
        ],
        is_exclude_priceLimit: [
          {text: 'N', value: false},
          {text: 'Y', value: true}
        ],
        goodsHealthRating: [
          {text: 'A', value: 'A'},
          {text: 'B', value: 'B'},
          {text: 'C', value: 'C'},
        ],
        yntf: [
          {text: 'Y', value: true},
          {text: 'N', value: false}
        ],
        nyft: [
          {text: 'N', value: false},
          {text: 'Y', value: true}
        ],
      },
      optionMap: {
        syncOriginMap: {},
        fetchMap: this.$utils.arr2map(this.$C.SHOP_FETCH_TYPE, 'code', 'name')
      },
      formOptions: formOptionsGen(this),
      formFields: formFieldsGen(),
      defaultFields: 'price:range,sku_id:eq,matched_sku_id:eq,b_rank:eq,season:eq,options.Size:like',
      customFormFields: [],
      collapse: {side: false, exchange: false, meta: false, detail: false},
    }
  },
  async created() {
    // 기존 collapse 에 key 가 없으면 vue 연동이 되지 않는다. status load 후 false 로 넣어준다.
    const collapseKeys = Object.keys(this.collapse);
    // 마지막 상태를 가져온다
    this.$utils.getStatus('Shop', this);
    collapseKeys.forEach(k => this.collapse[k] = !!this.collapse[k]);
    Vue.set(this, 'form', this.$utils.clone(this.defaultForm));

    this.getFx();
    this.getUsers().then();
    this.getMeta().then();

    // 옵션 정비
    this.optionMap.syncOriginMap = this.$utils.arr2map(this.options.sync_origin, 'value', 'text');

    await this.list();
    const id = this.$route.params.id;
    if (id) {
      this.showModal(id);
    }
  },
  async mounted() {
    let elem = document.createElement('script');
    elem.setAttribute('src', "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");
    document.head.appendChild(elem);
  },
  watch: {
    collapse: {
      deep: true,
      handler() {
        // 마지막 상태를 저장한다
        this.$utils.setStatus('Shop', this, 'collapse');
      }
    }
  },
  methods: {
    resetForm() {
      const fields = this.form.fields;
      this.form = this.$utils.clone(this.defaultForm);
      Vue.set(this.form, 'fields', fields);

      this.$refs.fields.resetFieldValues();
    },
    getFx() {
      this.$api.getJson('/shop/shinhanFx').then(j => {
        if (!j.data.dataBody) { // 고시환율이 확정전 상태입니다. 고시환율 확정후 조회할 수 있습니다.
          setTimeout(this.getFx, 3000);
          return;
        }
        this.exchangeRealtimeDt = j.data.dataBody['고시일자_display'] + ' ' + j.data.dataBody['고시시간_display'];
        j.data.dataBody.R_RIBF3733_1.forEach(e => {
          this.exchangeRealtimeMap[e['통화CODE']] = e;
        });
        this.$api.postJson('/shop/updateFx', {d: j.data.dataBody['고시일자_display'], dt: this.exchangeRealtimeDt, ...this.exchangeRealtimeMap});
      });
    },
    async getUsers() {
      this.options.users = (await this.$api.getJson('/user/list?proj=id,name,group,teamId')).list.map(e => ({
        text: `[${(this.teamMap[e.teamId] || {}).text}] ${e.name}(${e.id})`,
        group: e.group,
        teamId: e.teamId,
        name: e.name,
        value: e.id
      })).keySort('text');
      this.options.users.forEach(e => this.userMap[e.value] = e);
    },
    async getMeta() {
      let meta = this.meta = await this.$api.getMeta('price,exchange,category');
      if (meta) {
        meta.price.forEach(e => {
          this.priceMap[e.price_meta_name] = e.price_meta_value;
        });
        this.priceMeta = this.$C.PRICE_META.filter(e => !e.readonly).map(e => {
          return {...e, value: this.priceMap[e.name]};
        });
        meta.exchange.forEach(e => {
          this.exchangeMap[e.curr_unit] = e.exchange_ratio;
        });
        this.categoryMap = meta.category.reduce((prev, cur) => {
          prev[cur.category] = cur;
          return prev;
        }, {});
      }
    },
    addShop() {
      this.modalMode = 'add';
      this.$refs.shopModal.init(this.defaultShopObject);
      // this.modal.detail = true;
    },
    async applyExchange() {
      let j = await this.$api.postJson('/shop/applyExchange', {exchangeMap: this.exchangeMap});
      if (j) {
        j.exchange.forEach(e => {
          this.exchangeMap[e.curr_unit] = e.exchange_ratio;
        });
        alert('적용되었습니다');
      }
    },
    async applyPriceMeta() {
      let j = await this.$api.postJson('/shop/applyPriceMeta', {meta: this.priceMeta});
      if (j) {
        this.priceMeta.forEach(e => {
          this.priceMap[e.price_meta_name] = e.price_meta_value;
        });
        alert('적용되었습니다');
      }
    },
    makeListFormBody() {
      const form = this.form;
      const shop = form.shop.map(e => e.shop_id);
      const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];

      const body = {
        ...form,
        fields,
        shop,
      };
      if (this.query.trim()) {
        try {
          eval('body._query = {' + this.query + '}');
        } catch (e) {
          //
        }
      }
      return body;
    },
    async list(more) {
      const body = {form: this.makeListFormBody()};
      await this.$api.postTable(this, '/shop/list', body, {more, fnAssign: this.assignTableData});
    },
    assignTableData(s) {
      const rebateRemove = [['remove_customs_on_rebate', '관세'], ['remove_vat_on_rebate', '부가세']].filter(e => s[e[0]] === 'y').map(e => e[1]).join(',');
      const taxIssue = Object.entries({
        customs_included: '관세별도', // 가격에 관세, 개소세가 미포함
        vat_included: '부가세별도', // 가격에 부가세가 미포함
      // }).filter(([k, v]) => s[k] === 'n').map(([k, v]) => `<span class="badge alert-info">${v}</span>`).join(' ') + '<br/>' + Object.entries({
      }).filter(([k, v]) => s[k] === 'n').map(([k, v]) => v).concat(Object.entries({
        fixed_customs_yn: `일괄관세 ${s.customs_rate} %`,
        fta_yn: 'FTA(관세 0%)',
        tax_included_by_price: `${s.tax_included_if_price} ${s.currency} 이상 세금포함`, //  ~~ 이상일 때 세금포함
        idv_tax_yn: '개소교육세 있음', // 개별소비세, 교육세 있음
        customs_vat_with_delivery_yn: '해외배송세 있음', // 해외배송비에 관부가세 있음
        // vat_with_idv_tax_yn:'관세, 개소세에 부가세 없음',
      // }).filter(([k, v]) => s[k] === 'y').map(([k, v]) => `<span class="badge alert-success">${v}</span>`).join(' ');
      }).filter(([k, v]) => s[k] === 'y').map(([k, v]) => v));
      let deliCost = (s.local_delivery_cost_type === 'local' ? `${this.$utils.nvl(s.local_delivery_cost, '-')} ${s.currency}` : `${this.$utils.nvl(s.local_delivery_cost_krw, '-')} 원`) + '<br/>'
        + ({
          local: `${this.$utils.nvl(s.local_delivery_supply_cost, '-')} ${s.currency}`,
          krw: `${this.$utils.nvl(s.local_delivery_supply_cost_krw, '-')} 원`
        }[s.local_delivery_supply_cost_type] || '-');

      let marginStr = s.margin_rate ? `${s.margin_rate} %` : '';
      if (s.margin_if != null && s.margin_if !== "" && s.margin_if_over != null && s.margin_if_over !== "") {
        if (s.margin_if === s.margin_if_then && s.margin_if_over === s.margin_if_over_then) {
          marginStr = `${s.margin_if} % ~ ${s.margin_if_over} %`;
        } else {
          marginStr = `${s.margin_if} % ~ ${s.margin_if_over} %<br/>(각 ${s.margin_if} % 이하, ${s.margin_if_over} % 이상인 경우)`;
        }
      } else if ((s.margin_if != null && s.margin_if !== "") || (s.margin_if_over != null && s.margin_if_over !== "")) {
        marginStr = [(s.margin_if != null && s.margin_if !== "" ? `${s.margin_if_then} %(${s.margin_if} % 이하일 경우)` : ''),
          (s.margin_if_over != null && s.margin_if_over !== "" ? `${s.margin_if_over_then} %(${s.margin_if_over} % 이하일 경우)` : '')].filter(e => e).join('<br/>');
      }
      // marginStr = marginStr ? `<span class="badge badge-info" title="${marginStr}">마진설정 있음</span>` : '-';
      marginStr = '';

      const addressStyle = 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;';
      const gradeStr = {
        'premium': 'PREMIUM',
        'smart': 'SMART',
        'normal': '일반등급',
        'starter1': 'STARTER 1',
        'starter2': 'STARTER 2',
        'none': '등급없음',
      }[s.partnerGrade] || '확인 필요';
      const gradeVariant = {
        'premium': 'badge-primary',
        'smart': 'alert-success',
        'normal': 'alert-info',
        'starter1': 'badge-info',
        'starter2': 'badge-info',
        'none': 'badge-light'
      }[s.partnerGrade] || 'badge-danger';

      s.value = s.boutique;
      s.label = `${s.shop_id}. ${s.boutique}`;
      s.html1 = `${s.boutique} <span class="badge badge-success">${this.optionMap.syncOriginMap[s.sync_origin] || ''}</span>` +
        `${s.sync_origin === 'feed' ? ` <span class="badge badge-light">${this.optionMap.fetchMap[s.fetch_type] || ''}</span>` : ''}` +
        `${s.is_test_shop ? ` <span class="badge alert-warning">Test Shop</span>` : ''}` +
        `<span class="ml-1 badge ${gradeVariant}">${gradeStr}</span>`
      /*
        `<span class="ml-1 badge ${gradeVariant}">${gradeStr}</span><br/>` +
        `<small class="text-muted" style="${addressStyle}">주소: ${s.address || ''}</small>` +
        `<small class="text-muted" style="${addressStyle}">반품: ${s.return_address || ''}</small>`;
      */
      // s.html2 = `${COL_DICT[s.shop_type]}`;
      s.html3 = s.delivery_type === 'both' ? `<span class="badge alert-warning">${COL_DICT[s.delivery_type]}</span><br/>${s.delivery_day} 일(국내)<br/>${s.delivery_day_abroad} 일(해외)` :
        `<span class="badge alert-${s.delivery_type === 'abroad' ? 'success' : 'info'}">${COL_DICT[s.delivery_type]}</span><br/>${s.delivery_day} 일`;
      s.html6 = `${COL_DICT[s.shop_type]}<br/>${s.shop_country || ''}<br/>${s.currency || ''}`;
      s.html8 = `${deliCost}`;
      s.html9 = `<span class="badge alert-${s.return_ok === 'y' ? 'success' : 'danger'}">${s.return_ok === 'y' ? '가능' : '불가능'}${s.direct_return_yn === 'y' ? '(직접)' : ''}</span><br/>` +
        (s.return_ok === 'y' ? `${s.delivery_type === 'abroad' ? '-' : this.$utils.rnc(s.return_cost)}<br/>` +
          `${s.delivery_type === 'domestic' ? '-' : this.$utils.rnc(s.delivery_type === 'both' ? s.return_cost_abroad : s.return_cost)}` : '');

      // 내부 인원일 때 추가정보
      if (this.$R('SHOP_R')) {
        s.html4 = marginStr + (s.rebate_rate ? `<br/><span class="badge badge-light">수수료 : ${s.rebate_rate} %` + (rebateRemove ? `(${rebateRemove} 제외)` : '') + '</span>' : '')
          + (s.rebate_rate_used ? `<br/><span class="badge badge-secondary">중고 수수료 : ${s.rebate_rate_used} %</span>` : '');
        s.lambda1 = e => `<span class="badge badge-${e.price_calculation === 'auto' ? (e._notExistsMeta.length > 0 ? 'warning' : 'success') : 'secondary'}">`
          + `${e.price_calculation === 'auto' ?
            '자동' + (e._notExistsMeta.length > 0 ? `<i class="fa fa-exclamation-circle" title="다음 필드의 설정이 필요합니다 : ${e._notExistsMeta.join(', ')}"></i>` : '') :
            '수동'}</span>`
          + '<br/>'
          + (e.dynamicPricingEnabled ?
            '<span class="badge alert-primary">' + ((e.dpPositiveBaseRate || 0) + (e.dpPositiveCondRate || 0) + (e.dpPositiveBalaanRate || 0)) + ' %</span>' :
            '-')
          + '<br/>'
          + (taxIssue.length ? `<span class="badge alert-warning" title="${taxIssue.join('\n')}">세금별도: ${taxIssue.length}</span><br/>` : '-');
        s.lambda3 = e => [e.md_id && this.userMap[e.md_id] ? `${this.userMap[e.md_id].name}` : '-',
          e.op_id && this.userMap[e.op_id] ? `${this.userMap[e.op_id].name}` : '-',
          e.dev_id && this.userMap[e.dev_id] ? `${this.userMap[e.dev_id].name}` : '-'].join('<br/>');
        s.lambda4 = e => [e.contract_date || '-', e.display_date || '-'].join('<br/>');
      } else {
        s.html4 = '';
        s.lambda1 = () => '';
        s.lambda2 = () => '';
        s.lambda3 = () => '';
        s.lambda4 = () => '';
      }

      if (s.use_yn !== 'y') s._rowVariant = 'secondary';
    },
    async showDetail(row) {
      // list 상의 상세 버튼
      this.showModal(row.item.shop_id);
    },
    async showModal(shopId) {
      this.busy.detail = true;
      const j = await this.$api.getJson('/shop/detail/' + shopId);
      this.busy.detail = false;

      if (j) {
        j.shop.addr = j.shop.addr || {addressDetail: j.shop.address, returnAddressDetail: j.shop.return_address};
        j.shop.link_items = j.shop.link_items || [];
        this.modalMode = 'edit';
        j.shop.is_exclude_priceLimit = j.shop.is_exclude_priceLimit || false;
        this.$refs.shopModal.init(j.shop);
        // this.item_org = j.shop;
        // this.item = JSON.parse(JSON.stringify(j.shop));
        // this.modal.detail = true;
      }
    },
    async downAll(type) {
      try {
        this.busy.xlsx = true;
        const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];
        const body = {form: {...this.form, shop: this.form.shop.map(e => e.shop_id), fields}};
        delete body.form.limit;
        if (this.query.trim()) {
          try {
            eval('body.form._query = {' + this.query + '}');
          } catch (e) {
            //
          }
        }
        const j = await this.$api.postJson('/shop/list', body);
        this.down(type, {listItems: j.list});
        this.busy.xlsx = false;
      } catch (e) {
        return alert('엑셀 다운 중 오류가 발생했습니다.');
      }
    },
    async down(type, {listItems} = {}) {
      let items = this.items.list.filter(e => e.selected);

      if (listItems &&  listItems.length) {
        items = listItems;
      }
      if (!items.length) return alert('다운받을 SHOP 을 선택해주세요');

      const reasonSelected = await this.checkReason();
      if (!reasonSelected) return;

      const shopIds = items.map(e => e.shop_id);

      this.busy.xlsxDown = true;

      if (type === 'raw') {
        const fields = ('shop_id,address,boutique,currency,delivery_cost,delivery_day,fta_yn,local_delivery_cost,rebate_rate,rebate_rate_used,shop_country,' +
          'shop_type,categoryWhiteList,use_yn,delivery_type,' +
          'price_calculation,pccc_feed,pccc_require,apply_consumer_customs,apply_newin_customs,apply_sale_customs,' +
          'apply_supply_customs,md_id,name_open,stock_manage,expose_goods_link,init_display_status,goods_name_policy,explain,fetch_type,' +
          'local_delivery_cost_krw,local_delivery_cost_type,mapped_consumer_type,mapped_price_type,consumer_base,' +
          'consumer_iva_type,customs_included,customs_rate,customs_vat_with_delivery_yn,domestic_delivery_cost,fixed_customs_yn,idv_tax_yn,' +
          'remove_customs_on_rebate,remove_vat_on_rebate,sale_base,sale_iva_type,supply_base,supply_iva_type,tax_included_by_price,tax_included_if_price,' +
          'vat_included,vat_with_idv_tax_yn,logistics,contract_yn,newin_base,newin_iva_type,apply_consumer_krw_rate,' +
          'apply_consumer_rate,apply_newin_krw_rate,apply_newin_rate,apply_sale_krw_rate,apply_sale_rate,apply_supply_krw_rate,apply_supply_rate,' +
          'consumer_abroad_delivery,consumer_include_domestic_delivery,consumer_include_pg,iva_rate,local_delivery_avg_cost_max,local_delivery_avg_cost_per,' +
          'local_delivery_supply_cost,local_delivery_supply_cost_krw,local_delivery_supply_cost_type,newin_abroad_delivery,newin_include_domestic_delivery,' +
          'newin_include_pg,newin_season,sale_abroad_delivery,sale_include_domestic_delivery,sale_include_pg,supply_abroad_delivery,' +
          'supply_include_domestic_delivery,supply_include_pg,op_id,exchange_cost,return_cost,display_date,developed_date,apply_sale_krw_divider,' +
          'delivery_day_abroad,apply_newin_krw_divider,dev_id,desc,name,vaRate,settlement_type,apply_consumer_divider,apply_newin_divider,' +
          'apply_sale_divider,apply_supply_divider,homepage,org_shop_id,mapped_supply_type,mall_policy,img,contact,return_ok,apply_consumer_krw_divider,' +
          'category_discount,exchange_cost_abroad,return_cost_abroad,is_return_ok,contract_date,delivery,ep_fast_delivery,bPriceExclude,cardPromotionEnabled,' +
          'partnerGrade,goodsHealthRating')
          .split(',');
        const j = await this.$api.postJson('/shop/down', {shopIds, fields});
        if (j) {
          j.list.forEach(row => {
            const categoryWhiteList = row.categoryWhiteList || [];

            row.categoryWhiteList = categoryWhiteList.map(category => this.categoryMap[category].category_nm).join(',');
          });
          down(j.list, null, fields, `Shops_${this.$utils.dt()}`, 'xlsx');
        }
      } else {
        const fieldsMapping = {
          'shop_id': '샵 번호',
          'boutique': '샵 이름',
          'address': '주소',
          'currency': '통화',
          'use_yn': '사용여부',
          'md_name': 'MD 담당자',
          'md_mobile': 'MD 담당자 연락처',
          'md_email': 'MD 담당자 이메일',
          'cs_name':  'CS 담당자',
          'cs_mobile':  'CS 담당자 연락처',
          'cs_email':  'CS 담당자 이메일',
          'op_name': '운영 담당자',
          'dev_name': '개발 담당자',
          'shop_type': '샵 유형',
          'logistics': '직배송여부',
          'domestic_delivery_cost': '국내배송비',
          'rebate_rate': '리베이트율',
          'customs_rate': '관세율',
          'biz_email': '사업자 대표 이메일',
          'biz_owner': '사업자 대표 이름',
        }
        const fields = [
          "delivery_cost",
          "delivery_day",
          "fta_yn",
          "local_delivery_cost",
          "shop_country",
          "delivery_type",
          "contract_yn",
          "name_open",
          "img",
          "homepage",
          "desc",
          "price_calculation",
          "pccc_feed",
          "pccc_require",
          "fetch_type",
          "local_delivery_cost_krw",
          "local_delivery_cost_type",
          "mapped_consumer_type",
          "mapped_price_type",
          "consumer_base",
          "customs_vat_with_delivery_yn",
          "fixed_customs_yn",
          "idv_tax_yn",
          "local_delivery_avg_cost_krw",
          "local_delivery_avg_cost",
          "local_delivery_avg_cost_type",
          "rebate_rate_used",
          "remove_customs_on_rebate",
          "remove_vat_on_rebate",
          "sale_base",
          "supply_base",
          "tax_included_by_price",
          "tax_included_if_price",
          "vat_with_idv_tax_yn",
          "consumer_iva_type",
          "sale_iva_type",
          "supply_iva_type",
          "customs_included",
          "vat_included",
          "_dt",
          "manager"
        ];
        const j = await this.$api.postJson('/shop/down', {shopIds, fields: fields.concat(['md_id', 'op_id', 'dev_id', 'service_center']).concat(Object.keys(fieldsMapping))});
        fieldsMapping.email= '이메일';
        fieldsMapping.phone = '연락처';

        if (j) {
          const data = [];
          j.list.forEach(e => {
            e.md_name = this.userMap[e.md_id] && this.userMap[e.md_id].name || '';
            e.op_name = this.userMap[e.op_id] && this.userMap[e.op_id].name || '';
            e.dev_name = this.userMap[e.dev_id] && this.userMap[e.dev_id].name || '';
            if (e.service_center) {
              e.email = e.service_center.email;
              e.phone = e.service_center.phone;
            }
            if (e.manager) {
              e.md_name = e.manager.md_name;
              e.md_mobile = e.manager.md_mobile;
              e.md_email = e.manager.md_email;
              e.cs_name = e.manager.cs_name;
              e.cs_mobile = e.manager.cs_mobile;
              e.cs_email = e.manager.cs_email;
            }
            e.shop_type = COL_DICT[e.shop_type] || '';

            let obj = {};
            for (const [key, value] of Object.entries(fieldsMapping)) {
              obj[value] = e[key];
             delete e[key];
            }
            obj = Object.assign(obj, e);
            data.push(obj)
          });
          down(data, null, Object.values(fieldsMapping).concat(fields), `Shops_${this.$utils.dt()}`, type);
          this.sendPrivateLog('down', {type}, '다운로드', data.length);
        }
      }
      this.busy.xlsxDown = false;
    },
    copyShopID() {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('Shop ID 를 복사할 Shop을 선택해주세요');
      let res = this.$utils.copyToClipboard(selected.map(e => e.shop_id).join('\n'));
      if (res) this.$alertTop(`복사되었습니다`);
    },
    changeShopUseYN() {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('사용여부를 변경할 Shop을 선택해주세요.');
      this.modal.shopUseYn = true;
    },
    async updateUseYn() {
      const selectedCnt = this.items.list.filter(e => e.selected).length;
      const useYnText = this.item.useYnselected === 'y' ? '사용' : '미사용';
      const item = {};
      item.shop = this.items.list.filter(e => e.selected && e.use_yn !== this.item.useYnselected).map(e => e.shop_id);

      item.use_yn = this.item.useYnselected;
      if (item.use_yn === 'n') {
        item.use_yn_reason = this.item.use_yn_reason;
        item.use_yn_reason_detail = this.item.use_yn_reason_detail;
      }
      let j = await this.$api.postJson('/shop/updateUseYn', {item});
      if (j) {
        alert(`선택한 Shop 총 ${selectedCnt}개 중 ${j.count || 0}개가 ${useYnText}으로 변경되었습니다.`);
        this.modal.shopUseYnReason = false;
        await this.list();
      }
    },
    inputReason(text) {
      if (text.length > 100) {
        this.item.use_yn_reason_detail = text.slice(0, 100);
      }
    },
    async modalHide(event) {
      if (event.trigger === 'ok') {
        if (this.item.useYnselected === 'n' && (!this.item.use_yn_reason || !this.item.use_yn_reason_detail.trim())){
          alert('미사용 처리 사유를 입력해야합니다.');
          event.preventDefault();
        } else {
          this.modal.shopUseYnReason = true;
        }
      } else if (~['cancel', 'headerclose', 'esc', 'backdrop'].indexOf(event.trigger)) {
        if (event.trigger === 'cancel' || event.trigger === 'headerclose') {
          if (this.item.useYnselected === 'n' && !confirm('변경사항을 저장하지 않으시겠습니까?')) {
            event.preventDefault();
          }
        } else {
          event.preventDefault && event.preventDefault(); // 이벤트 무시
        }
      }
    },
    async checkReason() {
      this.modal.download = true;
      return new Promise(resolve => {
        this.formDownReason.resolve = b => {
          if (b && this.formDownReason.reason === 'etc' && this.formDownReason.reasonEtc.trim() === '') {
            return alert('사유를 입력해주세요.');
          }
          resolve(b);
          this.modal.download = false;
        }
      });
    },
    async sendPrivateLog(func, form, type, count) {
      const baseUrl = '/shop@';
      const reason = this.formDownReason.reason === 'etc' ? this.formDownReason.reasonEtc : this.formDownReason.reason;
      await this.$api.postJson('/order/privateLog', {func: baseUrl + func, form, type, count, reason});
      this.formDownReason.reason = 'order';
      this.formDownReason.reasonEtc = '';
    }
  }
}
</script>
