<template>
  <div>
    <div class="title-sm">수집가격 종류</div>
    <b-row>
      <b-col>
        <small>연동유형</small><br/>
        <b-form-select class="col-form-label text-center" v-model="item.fetch_type"
                       :options="$C.SHOP_FETCH_TYPE.map(e=>({text:e.name, value:e.code}))"></b-form-select>
      </b-col>
      <b-col>
        <small>Mapped Consumer</small><br/>
        <b-form-input class="text-center" v-model="item.mapped_consumer_type"></b-form-input>
      </b-col>
      <b-col>
        <small>Mapped Price</small><br/>
        <b-form-input class="text-center" v-model="item.mapped_price_type"></b-form-input>
      </b-col>
      <b-col>
        <small>Mapped Supply</small><br/>
        <b-form-input class="text-center" v-model="item.mapped_supply_type"></b-form-input>
      </b-col>
      <b-col>
        <small>적용환율({{ item.currency }})</small><br/>
        <label class="col-form-label">{{ exchangeMap[item.currency] }} 원</label>
      </b-col>
    </b-row>
    <div class="title-sm clearfix">
      <div class="pull-left">
        개별가격조건 설정
      </div>
      <div class="pull-right">
        <span>시뮬레이션 &nbsp;</span>
        <c-switch v-model="simulate" style="vertical-align:middle;margin-top:-5px" color="success" label variant="pill" @click="calc()"/>
      </div>
    </div>

    <div v-if="simulate" class="simulate center p-2 mb-2">
      <b-row class="mb-2">
        <b-col>
          <small>Mapped Consumer</small>
          <b-input-group>
            <b-form-input class="text-center" v-model.number="sField.mappedConsumer"></b-form-input>
            <b-input-group-append>
              <b-input-group-text>{{ item.currency }}</b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col>
          <small>Mapped Price</small><br/>
          <b-input-group>
            <b-form-input class="text-center" v-model.number="sField.mappedPrice"></b-form-input>
            <b-input-group-append>
              <b-input-group-text>{{ item.currency }}</b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col>
          <small>Mapped Supply</small><br/>
          <b-input-group>
            <b-form-input class="text-center" v-model.number="sField.mappedSupply"></b-form-input>
            <b-input-group-append>
              <b-input-group-text>{{ item.currency }}</b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <small>카테고리</small><br/>
          <b-input class="text-center" v-model="sField.category" placeholder="WCL 형태의 카테고리"></b-input>
        </b-col>
        <b-col>
          <small>시즌</small><br/>
          <b-input class="text-center" v-model="sField.launch_date" placeholder="시즌"></b-input>
        </b-col>
        <b-col>
          <small>상품유형</small><br/>
          <b-form-radio-group class="col-form-label" v-model="sField.goodsType" :options="[
            {text: '새상품', value: 'new'},
            {text: '빈티지', value: 'used'}
          ]"></b-form-radio-group>
        </b-col>
        <b-col>
          <small>FTA</small><br/>
          <b-form-radio-group class="col-form-label" v-model="sField.fta" :options="options.yn"></b-form-radio-group>
        </b-col>
      </b-row>
    </div>

    <template v-if="simulate && item.rebate_rate">
      <div class="title-sm clearfix">
        결과 요약
      </div>
      <div class="summary mb-2">
        <b-row>
          <b-col>판매가 적용항목</b-col>
          <b-col style="font-weight: bold;background-color: #f2f2f2;">판매가</b-col>
          <b-col>정산가 적용항목</b-col>
          <b-col style="font-weight: bold;background-color: #f2f2f2;">정산가</b-col>
        </b-row>
        <b-row>
          <b-col>기본가격</b-col>
          <b-col>{{$utils.comma(sCalc.sale.basePrice)}}</b-col>
          <b-col>기본가격</b-col>
          <b-col>{{$utils.comma(sCalc.supply.basePrice)}}</b-col>
        </b-row>
        <b-row v-if="item.apply_sale_rate || item.apply_supply_rate">
          <b-col>할인할증({{item.apply_sale_rate || 0}} %) 적용</b-col>
          <b-col>{{$utils.cnc(sCalc.sale.basePrice * (100 + +item.apply_sale_rate) / 100)}}</b-col>
          <b-col>할인할증({{item.apply_supply_rate || 0}} %) 적용</b-col>
          <b-col>{{$utils.cnc(sCalc.supply.basePrice * (100 + +item.apply_supply_rate) / 100)}}</b-col>
        </b-row>
        <b-row v-if="item.apply_sale_divider || item.apply_supply_divider">
          <b-col>divider({{item.apply_sale_divider}}) 적용</b-col>
          <b-col>{{$utils.comma(sCalc.sale.localPrice)}}</b-col>
          <b-col>divider({{item.apply_supply_divider}}) 적용</b-col>
          <b-col>{{$utils.comma(sCalc.supply.localPrice)}}</b-col>
        </b-row>
        <b-row v-if="item.apply_sale_operate_rate || item.apply_supply_operate_rate">
          <b-col>운영 할인할증({{item.apply_sale_operate_rate || 0}} %) 적용</b-col>
          <b-col>{{$utils.comma(sCalc.sale.operateAdjustPrice)}}</b-col>
          <b-col>운영 할인할증({{item.apply_supply_operate_rate || 0}} %) 적용</b-col>
          <b-col>{{$utils.comma(sCalc.supply.operateAdjustPrice)}}</b-col>
        </b-row>
        <b-row v-if="item.apply_sale_partner_rate || item.apply_supply_partner_rate">
          <b-col>파트너 할인할증({{item.apply_sale_partner_rate || 0}} %) 적용</b-col>
          <b-col>{{$utils.comma(sCalc.sale.partnerAdjustPrice)}}</b-col>
          <b-col>파트너 할인할증({{item.apply_supply_partner_rate || 0}} %) 적용</b-col>
          <b-col>{{$utils.comma(sCalc.supply.partnerAdjustPrice)}}</b-col>
        </b-row>
        <b-row v-if="item.apply_sale_partner_grade_rate || item.apply_supply_partner_grade_rate">
          <b-col>샵 별 전체 할인할증({{item.apply_sale_partner_grade_rate || 0}} %) 적용</b-col>
          <b-col>{{$utils.comma(sCalc.sale.partnerGradeRatePrice)}}</b-col>
          <b-col>샵 별 파트너 할인할증({{item.apply_supply_partner_grade_rate || 0}} %) 적용</b-col>
          <b-col>{{$utils.comma(sCalc.supply.partnerGradeRatePrice)}}</b-col>
        </b-row>
        <b-row>
          <b-col>최종 판매가</b-col>
          <b-col>{{$utils.comma(sCalc.finalPriceRoundUp)}}</b-col>
          <b-col>정산 기준가</b-col>
          <b-col>{{$utils.comma(sCalc.supply.standardPrice)}}</b-col>
        </b-row>
        <b-row>
          <b-col>PG 수수료</b-col>
          <b-col>- {{$utils.comma(sCalc.pgFee)}}</b-col>
          <b-col>판매 수수료</b-col>
          <b-col>- {{$utils.comma(sCalc.rebate)}}</b-col>
        </b-row>
        <b-row>
          <b-col>수수료 제외 매출</b-col>
          <b-col>{{$utils.comma(sCalc.sale.finalPriceRoundUp - sCalc.pgFee)}}</b-col>
          <b-col>최종 정산가</b-col>
          <b-col>{{$utils.comma(sCalc.supply.standardPrice - sCalc.rebate)}}</b-col>
        </b-row>
        <b-row>
          <b-col cols="3">마진</b-col>
          <b-col cols="9">
            {{$utils.comma(sCalc.sale.finalPriceRoundUp - sCalc.pgFee)}} -
            {{$utils.comma(sCalc.supply.standardPrice - sCalc.rebate)}} =
            {{$utils.comma(sCalc.margin)}} ({{sCalc.marginRate}} %)
          </b-col>
        </b-row>
      </div>
    </template>

    <div v-if="simulate" class="text-right">
      <b-input size="sm" style="display:inline-block;vertical-align:middle;margin-right:0.25rem;width:250px;text-align:center" v-model="sampleGood.goods_id"
               placeholder="발란코드 or goods_id"></b-input>
      <b-button variant="outline-primary" size="sm" class="mr-1" @click="getSelectedPrice()">
        <b-spinner v-if="busy.selected" small variant="info"></b-spinner>
        선택상품
      </b-button>
      <b-button variant="outline-secondary" size="sm" class="mr-1" @click="getRandomPrice()">
        <b-spinner v-if="busy.random" small></b-spinner>
        랜덤상품
      </b-button>
      <b-button variant="outline-warning" size="sm" class="mr-1" @click="jsonModal('계산결과 Raw Data', sCalc)">RawData</b-button>
      <b-button variant="outline-success" size="sm" class="mr-1" @click="sampleDown()">샘플 xlsx 다운</b-button>
      <b-button variant="primary" size="sm" class="mr-1" @click="calc()">계산하기</b-button>
      <b-button variant="outline-primary" size="sm" @click="readonly.parallel = !readonly.parallel">readonly 토글(관리자)</b-button>
    </div>

    <b-row class="text-center my-1">
      <b-col></b-col>
      <b-col>Consumer</b-col>
      <b-col>Sale</b-col>
      <b-col>NewIn</b-col>
      <b-col>Supply</b-col>
    </b-row>

    <b-row class="text-center my-1">
      <b-col><label class="col-form-label">Base 가격</label></b-col>
      <b-col>
        <b-form-select class="col-form-label" v-model="item.consumer_base" :options="options.basePrice">
        </b-form-select>
      </b-col>
      <b-col>
        <b-form-select class="col-form-label" v-model="item.sale_base" :options="options.basePrice">
        </b-form-select>
      </b-col>
      <b-col>
        <b-form-select class="col-form-label" v-model="item.newin_base" :options="options.basePrice">
        </b-form-select>
      </b-col>
      <b-col>
        <b-form-select class="col-form-label" v-model="item.supply_base" :options="options.basePrice">
        </b-form-select>
      </b-col>
    </b-row>

    <template v-if="simulate">
      <price-table-s-calc class="bold" v-bind="{sCalc, name: '기본현지가격', field: 'basePrice', type: 'rnc2', post: item.currency}"></price-table-s-calc>
    </template>

    <price-table-radio v-model="item" v-bind="{name: `IVA(${ item.iva_rate || priceMap.iva_rate }%) 증감`, field: `$price_iva_type`, options: options.pzn}">
    </price-table-radio>

    <price-table-input v-model="item" v-bind="{
      name: '현지가격 할인할증', field: `apply_$price_rate`, append: 'percent', readonly, readonlyKey: 'parallel', readonlyIndex: [0, 2]
    }"></price-table-input>

    <price-table-input v-model="item" v-bind="{
      name: '현지가격 divider', field: `apply_$price_divider`, readonly, readonlyKey: 'parallel', readonlyIndex: [0, 1, 2, 3],
      tooltip: '계산될 특정 가격에서 수수료 등의 몇% 를 제외해서 현재 가격을 맞추기 위한 개념으로, 계산가 = 현재가 / divider 가 됩니다. 만약 3.3% 라면 96.7 을 넣으시면 됩니다',
    }"></price-table-input>

    <price-table-input v-model="item" v-bind="{
      name: '현지가격 올림 자리수', field: `apply_$price_roundup`,
      tooltip: '자리수의 기본 표현은 소수점뒤 몇자리 여서 천원단위로 올림을 하려면 -3을 입력하면 됩니다',
    }"></price-table-input>

    <template v-if="simulate">
      <price-table-s-calc class="bold" v-bind="{sCalc, name: '세전 현지가격', field: 'localPrice', type: 'rnc2', post: item.currency}"></price-table-s-calc>
    </template>
    <!--
    <price-table-input v-model="item" v-bind="{
      name: '운영 할인할증', field: `apply_$price_operate_rate`, append: 'percent', readonly, readonlyKey: 'parallel', readonlyIndex: [0, 2],
      tooltip: '파트너 등급제 등 운영상의 할인할증 조정이며, 파트너 할인할증과 합산한 후 세전 현지가격에 곱셈으로 적용됩니다.',
    }"></price-table-input>

    <price-table-input v-model="item" v-bind="{
      name: '파트너 할인할증', field: `apply_$price_partner_rate`, append: 'percent', readonly, readonlyKey: 'parallel', readonlyIndex: [0, 2],
      tooltip: '파트너의 추가 할인할증 조정이며, 운영 할인할증과 합산한 후 세전 현지가격에 곱셈으로 적용됩니다.',
    }"></price-table-input>
    -->

    <b-row v-if="simulate && sCalc.dynamicPricing" class="simulate my-1">
      <b-col><label class="bold col-form-label">Dynamic Pricing</label></b-col>
      <b-col>
        <small>Mode</small><br/>
        <label class="col-form-label">{{ {unchanged: '가격변경없음', positive: '달성함', negative: '미달성'}[sCalc.dynamicPricing.mode] }}</label>
      </b-col>
      <b-col>
        <small>발란추천가</small><br/>
        <label class="col-form-label">{{ $utils.rnc(sCalc.dynamicPricing.minPriceAll) }} {{item.currency}}</label>
      </b-col>
      <b-col>
        <small>적용가</small><br/>
        <label class="col-form-label">{{ $utils.rnc(sCalc.dynamicPricing.adjustPrice) }} {{item.currency}}</label>
      </b-col>
      <b-col>
        <small>할인율</small><br/>
        <label class="col-form-label">{{ sCalc.dynamicPricing.discountRate }} %</label>
      </b-col>
    </b-row>

    <template v-if="simulate">
      <price-table-s-calc class="bold" v-bind="{sCalc, name: '조정현지가', field: 'adjustPrice', type: 'rnc2', post: item.currency}"></price-table-s-calc>
      <price-table-s-calc v-if="sCalc.prRule" class="bold" v-bind="{sCalc, name: '병행가격설정', type: 'prRule', post: item.currency}"></price-table-s-calc>
      <price-table-s-calc class="bold" v-bind="{sCalc, name: '판매기준가', field: 'standardPrice', type: 'rnc2', post: item.currency}"></price-table-s-calc>
    </template>

<!--    <price-table-input v-model="item" v-bind="{-->
<!--      name: '샵 별 정률 할인', field: `apply_$price_partner_grade_rate`, append: 'percent', readonly, readonlyKey: 'parallel', readonlyIndex: [0, 2],-->
<!--      tooltip: '파트너 등급제 할인(샵별 정률 할인)이며, 판매기준가 대비 차감되며 기획전 등과는 독립적으로 적용됩니다.',-->
<!--    }"></price-table-input>-->

    <template v-if="simulate">
      <price-table-s-calc class="simulate-white-bg" v-bind="{name: `적용환율(${item.currency})`, pre: 'x', post: '' + exchangeMap[item.currency]}">
      </price-table-s-calc>
      <price-table-s-calc class="bold" v-bind="{sCalc, name: '세전 KRW', field: 'befTaxPriceWithoutDelivery', type: 'rnc', post: '원'}"></price-table-s-calc>
    </template>

    <price-table-radio v-if="item.customs_vat_with_delivery_yn === 'y'" v-model="item"
                       v-bind="{name: '해외배송비 적용', field: `$price_abroad_delivery`, options: options.casn}">
    </price-table-radio>

    <template v-if="simulate && item.customs_vat_with_delivery_yn === 'y'">
      <price-table-s-calc class="" v-bind="{sCalc, name: '해외배송비 KRW', field: 'localDeliveryCost', type: 'rnc', post: '원'}"></price-table-s-calc>
      <price-table-s-calc class="bold" v-bind="{sCalc, name: '배송비포함 KRW', field: 'befTaxPrice', type: 'rnc', post: '원'}"></price-table-s-calc>
    </template>

    <price-table-radio v-model="item" v-bind="{name: '관세 적용', field: `apply_$price_customs`, options: options.yn}"></price-table-radio>

    <template v-if="simulate">
      <price-table-s-calc v-bind="{sCalc, name: `관세(${sCalc.base_customs_rate} %)`, field: 'cTax', type: 'rnc', post: '원'}"></price-table-s-calc>
      <price-table-s-calc v-bind="{sCalc, name: `개소세 및 교육세(${sCalc.idvEduRate} %)`, field: 'idvEduTax', type: 'rnc', post: '원'}"></price-table-s-calc>
      <price-table-s-calc v-bind="{sCalc, name: `부가세(${sCalc.vat} %)`, field: 'vatKrw', type: 'rnc', post: '원'}"></price-table-s-calc>
      <price-table-s-calc class="bold" v-bind="{sCalc, name: `세후 KRW`, field: 'vatPrice', type: 'rnc', post: '원'}"></price-table-s-calc>
    </template>

    <price-table-input v-model="item" v-bind="{
      name: '국내가격 할인할증', field: `apply_$price_krw_rate`, append: 'percent', readonly, readonlyKey: 'parallel', readonlyIndex: [0, 1, 2, 3]
    }"></price-table-input>

    <price-table-input v-model="item" v-bind="{
      name: '국내가격 divider', field: `apply_$price_krw_divider`, readonly, readonlyKey: 'parallel', readonlyIndex: [0, 1, 2, 3],
      tooltip: '계산될 특정 가격에서 수수료 등의 몇% 를 제외해서 현재 가격을 맞추기 위한 개념으로, 계산가 = 현재가 / divider 가 됩니다. 만약 3.3% 라면 96.7 을 넣으시면 됩니다',
    }"></price-table-input>

    <price-table-radio v-if="item.customs_vat_with_delivery_yn !== 'y'" v-model="item"
                       v-bind="{name: '해외배송비 적용', field: `$price_abroad_delivery`, options: options.casn}">
    </price-table-radio>

    <template v-if="simulate">
      <price-table-s-calc v-if="item.customs_vat_with_delivery_yn !== 'y'"
                          v-bind="{sCalc, name: `해외배송비(세금제외) KRW`, field: 'localDeliveryCost', type: 'rnc', post: '원'}"></price-table-s-calc>
      <price-table-s-calc class="bold" v-bind="{sCalc, name: `국내가격`, field: 'domesticPrice', type: 'rnc', post: '원'}"></price-table-s-calc>
      <price-table-s-calc v-if="item.rebate_rate" v-bind="{sCalc, name: `수수료`, type: 'rebate'}"></price-table-s-calc>
    </template>

    <price-table-radio v-model="item" v-bind="{name: '국내배송비 적용', field: `$price_include_domestic_delivery`, options: options.yn}"></price-table-radio>

    <b-row v-if="simulate" class="simulate my-1">
      <b-col><label class="col-form-label">국내 배송비</label></b-col>
      <b-col><label class="col-form-label"><span
        v-if="item.consumer_include_domestic_delivery === 'y'">{{ $utils.rnc(sCalc.consumer.domestic_delivery_cost) }} 원</span>
        <del v-else>{{ $utils.rnc(sCalc.consumer.domestic_delivery_cost) }} 원</del>
      </label></b-col>
      <b-col><label class="col-form-label"><span v-if="item.sale_include_domestic_delivery === 'y'">{{ $utils.rnc(sCalc.sale.domestic_delivery_cost) }} 원</span>
        <del v-else>{{ $utils.rnc(sCalc.sale.domestic_delivery_cost) }} 원</del>
      </label></b-col>
      <b-col><label class="col-form-label"><span v-if="item.newin_include_domestic_delivery === 'y'">{{ $utils.rnc(sCalc.newin.domestic_delivery_cost) }} 원</span>
        <del v-else>{{ $utils.rnc(sCalc.newin.domestic_delivery_cost) }} 원</del>
      </label></b-col>
      <b-col><label class="col-form-label"><span v-if="item.supply_include_domestic_delivery === 'y'">{{ $utils.rnc(sCalc.supply.domestic_delivery_cost) }} 원</span>
        <del v-else>{{ $utils.rnc(sCalc.supply.domestic_delivery_cost) }} 원</del>
      </label></b-col>
    </b-row>

    <price-table-radio v-model="item" v-bind="{name: 'PG수수료 적용', field: `$price_include_pg`, options: options.yn}"></price-table-radio>

    <b-row v-if="simulate" class="simulate my-1">
      <b-col><label class="col-form-label">PG 수수료({{ sCalc.pgRate }} %)</label></b-col>
      <b-col><label class="col-form-label"><span v-if="item.consumer_include_pg === 'y'">{{ $utils.rnc(sCalc.consumer.pgFee) }} 원</span>
        <del v-else>{{ $utils.rnc(sCalc.consumer.pgFee) }} 원</del>
      </label></b-col>
      <b-col><label class="col-form-label"><span v-if="item.sale_include_pg === 'y'">{{ $utils.rnc(sCalc.sale.pgFee) }} 원</span>
        <del v-else>{{ $utils.rnc(sCalc.sale.pgFee) }} 원</del>
      </label></b-col>
      <b-col><label class="col-form-label"><span v-if="item.newin_include_pg === 'y'">{{ $utils.rnc(sCalc.newin.pgFee) }} 원</span>
        <del v-else>{{ $utils.rnc(sCalc.newin.pgFee) }} 원</del>
      </label></b-col>
      <b-col><label class="col-form-label"><span v-if="item.supply_include_pg === 'y'">{{ $utils.rnc(sCalc.supply.pgFee) }} 원</span>
        <del v-else>{{ $utils.rnc(sCalc.supply.pgFee) }} 원</del>
      </label></b-col>
    </b-row>

    <template v-if="simulate">
      <price-table-s-calc class="bold" v-bind="{sCalc, name: `최종가격`, field: 'finalPrice', type: 'rnc', post: '원'}"></price-table-s-calc>
      <price-table-s-calc class="bold" v-bind="{sCalc, name: `최종가격(조정)`, field: 'finalPriceRoundUp', type: 'rnc', post: '원',
        tooltip: '10원 미만을 절삭하여 조정한 가격입니다'
      }"></price-table-s-calc>
    </template>

    <b-row v-if="simulate" class="my-1">
      <b-col cols="3">
        <label class="col-form-label bold">최종소비자가 <i class="fa fa-question-circle ml-1" v-b-tooltip="'판매가보다 작을 경우 판매가로 재설정합니다'"></i></label>
        <div class="text-right bold">{{ $utils.comma(sCalc.consumer.finalPriceRoundUp) }} 원</div>
      </b-col>
      <b-col cols="3">
        <label class="col-form-label bold">최종판매가 <i class="fa fa-question-circle ml-1" v-b-tooltip="'마진조건, 일괄마진을 적용한 뒤 천원 단위로 올림합니다'"></i></label>
        <div class="text-right bold">{{ $utils.comma(sCalc.finalPriceRoundUp) }} 원</div>
      </b-col>
      <b-col cols="3">
        <label class="col-form-label">최종할인율</label>
        <div class="text-right">{{ sCalc.discountRate.toFixed(2) }} %</div>
      </b-col>
      <b-col v-if="item.rebate_rate" cols="3">
        <label class="col-form-label bold">최종정산가 <i class="fa fa-question-circle ml-1" v-b-tooltip="'마진조건, 일괄마진을 적용한 뒤 천원 단위로 올림합니다'"></i></label>
        <div class="text-right bold">{{ $utils.comma(sCalc.supply.standardPrice - sCalc.rebate) }} 원</div>
      </b-col>
    </b-row>
    <b-row v-if="simulate" class="my-1">
      <b-col cols="3">
        <label class="col-form-label">
          기본마진
          <i v-if="item.rebate_rate" class="fa fa-question-circle ml-1" v-b-tooltip="'PG 수수료 적용전 단순 마진(최종판매가 - 최종정산가)'"></i>
          <i v-else class="fa fa-question-circle ml-1" v-b-tooltip="'PG 수수료 적용전 단순 마진(최종판매가 - 최종원가)'"></i>
        </label>
        <div class="text-right">{{ $utils.rnc(sCalc.befMargin) }} 원</div>
      </b-col>
      <b-col cols="3">
        <label class="col-form-label">
          최종마진
          <i class="fa fa-question-circle ml-1" v-b-tooltip="item.rebate_rate ?
            'PG 수수료가 적용된 마진(최종판매가 - PG 수수료 - 정산 금액)' :
            'PG 수수료가 적용된 마진(최종판매가 - PG 수수료 - PG수수료를 제외한 원가)'"></i>
        </label>
        <div class="text-right">{{ $utils.rnc(sCalc.margin) }} 원</div>
      </b-col>
      <b-col cols="3">
        <label class="col-form-label">
          최종마진율
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'최종판매가와 최종마진을 기준으로 계산한 마진율입니다'"></i></label>
        <div class="text-right">{{ sCalc.marginRate.toFixed(2) }} %</div>
      </b-col>
      <b-col cols="3">
        <label class="col-form-label">
          vat 제외 마진
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'최종마진에서 vat 를 제외한 값'"></i>
        </label>
        <div class="text-right">{{ $utils.rnc(sCalc.finalMargin) }} 원</div>
      </b-col>
      <!--<b-col cols="3">
        <label class="col-form-label">수정마진 <i class="fa fa-question-circle ml-1" v-b-tooltip="'마진조건, 일괄마진을 적용 후 최종판매가를 기준으로 단순 측정한 마진입니다'"></i></label>
        <div class="text-right">{{$utils.rnc(sCalc.margin)}} 원</div>
      </b-col>
      <b-col cols="3">
        <label class="col-form-label">수정마진율 <i class="fa fa-question-circle ml-1" v-b-tooltip="'최종판매가와 수정마진을 기준으로 계산한 마진율입니다'"></i></label>
        <div class="text-right">{{sCalc.marginRate.toFixed(2)}} %</div>
      </b-col>-->
    </b-row>

    <!--
      230628: 수집가격변화 대응은 기존 feed 에서 다루던 개념이나 더이상 참조하는 영역이 없고 일부 검색 정도에 사용된다. 주석처리한다.
        catch_sale_diff_* 필드는 기본값이 true 여서 사용중 샵들도 true 가 많지만, 실제로 동작하는 스레숄드인 sale_diff_*_rate 값이 설정된 샵들은 모두 미사용이다.
    -->
    <!--<div class="title-sm">수집가격변화 대응</div>
    <b-row>
      <b-col class="mb-2" cols="4" lg="3">
        <small>판매가 상승 대응</small><br/>
        <c-switch v-model="item.catch_sale_diff_up" class="mt-2" color="success" label variant="pill"/>
      </b-col>
      <b-col class="mb-2" cols="8" lg="9">
        <small>판매가 상승 조건식</small>
        <b-form inline>
          기존 판매가와 차이가 &nbsp;<b-input v-model.number="item.sale_diff_up_rate" class="w-50px"></b-input>
          % 이상일 때 변경
        </b-form>
      </b-col>
      <b-col class="mb-2" cols="4" lg="3">
        <small>판매가 하락 대응</small><br/>
        <c-switch v-model="item.catch_sale_diff_down" class="mt-2" color="success" label variant="pill"/>
      </b-col>
      <b-col class="mb-2" cols="8" lg="9">
        <small>판매가 하락 조건식</small>
        <b-form inline>
          기존 판매가와 차이가 &nbsp;<b-input v-model.number="item.sale_diff_down_rate" class="w-50px"></b-input>
          % 이상일 때 변경
        </b-form>
      </b-col>
    </b-row>-->
  </div>
</template>

<script>
import {Switch as cSwitch} from '@coreui/vue'
import * as utils from '@/shared/utils'
import {down} from '@/shared/impexp'
import PriceTableInput from './PriceTableInput.vue';
import PriceTableRadio from './PriceTableRadio.vue';
import PriceTableSCalc from './PriceTableSCalc.vue';
import Vue from "vue";

export default {
  name: "ShopPriceTable",
  components: {cSwitch, PriceTableInput, PriceTableRadio, PriceTableSCalc},
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    priceMap: Object,
    exchangeMap: Object,
    options: Object,
  },
  data() {
    return {
      defaultForm: {
        sCalc: {
          consumer: {},
          sale: {},
          newin: {},
          supply: {},
          customs_rate: 0,
          vat: 0,
          idvEduRate: 0,
          pgRate: 0,
          rebate: 0,
          margin: 0,
          befMargin: 0,
          marginRate: 0,
          discountRate: 0,
          finalMarginRate: 0,
          finalPrice: 0,
        }
      },
      modal: false,
      modalMode: 'edit',
      simulate: true,
      sampleGood: {goods_id: ''},
      ruleMap: {},
      sField: {
        mappedConsumer: 122000,
        mappedPrice: 100000,
        mappedSupply: 100000,
        category: 'WCL',
        brand_no: 395, // prada
        brand_name: 'PRADA',
        launch_date: '20SS',
        fta: 'n',
      },
      sCalc: {},
      busy: {rebatePrice: false, selected: false, random: false},
      readonly: {parallel: false}
    }
  },
  created() {
    Vue.set(this, 'sCalc', this.$utils.clone(this.defaultForm.sCalc));
    // if (this.$R('SHOP_R')) {
    //   this.getShopRebatePrice().then(this.getRandomPrice);
    // }
    this.getRandomPrice();
    if (this.item.shop_type === 'parallel') {
      this.readonly.parallel = true;
    }
    // this.calc();
  },
  watch: {
    item: {
      deep: true,
      handler(item) {
        // this.calc();
        // this.readonly.parallel = item.shop_type === 'parallel';
        item.apply_newin_rate = item.apply_sale_rate;
        item.apply_newin_operate_rate = item.apply_sale_operate_rate;
        item.apply_newin_partner_rate = item.apply_sale_partner_rate;
      },
    },
    sField: {
      deep: true,
      handler(item) {
        this.calc();
        // this.readonly.parallel = item.shop_type === 'parallel';
        item.apply_newin_rate = item.apply_sale_rate;
        item.apply_newin_partner_rate = item.apply_sale_partner_rate;
      },
    }
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    // async getShopRebatePrice() {
    //   this.busy.rebatePrice = true;
    //   let j = await this.$api.postJson('/price/priceRebateNow', {shop_id: [this.item.shop_id]});
    //   this.busy.rebatePrice = false;
    //   if (j) {
    //     this.ruleMap = j.ruleMap;
    //   } else {
    //     this.ruleMap = {};
    //   }
    // },
    async getSelectedPrice() {
      let goods_id = this.sampleGood.goods_id;
      this.busy.selected = true;
      let j = await this.$api.getJson(`/goods/goodSample?shop_id=${this.item.shop_id}&id=${goods_id}`);
      this.busy.selected = false;
      if (!j || !j.goods || !j.goods.length) return alert('해당하는 상품을 찾을 수 없습니다');
      let good = j.goods[0];

      good.goods_id = goods_id;
      this.sampleGood = good;
      this.calc(good.goods_no);
    },
    async getRandomPrice() {
      this.busy.random = true;
      let j = await this.$api.getJson('/goods/goodSample?shop_id=' + this.item.shop_id);
      this.busy.random = false;
      if (!j || !j.goods[0]) return;
      const good = j.goods[0];
      this.sampleGood = good;
      this.calc(good.goods_no);
    },

    /**
     * 서버측에서 계산하여 받아온다.
     */
    async calc(goods_no) {
      const sField = this.sField, exchangeMap = this.exchangeMap, priceMap = this.priceMap;
      let j;
      if (goods_no) {
        j = await this.$api.postJson('/shop/calc', {goods_no});
      } else {
        j = await this.$api.postJson('/shop/calc', {shop: this.item, sField, exchangeMap, priceMap});
      }
      if (j) {
        Vue.set(this, 'sCalc', this.$utils.clone(this.defaultForm.sCalc));
        Object.assign(this.sCalc, j.calc);
        this.$forceUpdate();
      }
    },
    async sampleDown() {
      let cnt = 200;
      if (!(cnt = prompt('다운받을 샘플 수를 입력해주세요', cnt)) || isNaN(cnt)) return;
      let j = await this.$api.getJson(`/goods/goodSample?cnt=${cnt}&shop_id=` + this.item.shop_id);
      if (!j) return;
      let rows = [];
      for (let good of j.goods) {
        let opt = good.options[0];
        this.sField = {
          mappedConsumer: opt.goods_consumer,
          mappedPrice: opt.goods_price,
          mappedSupply: opt.goods_supply,
          category: good.category,
          brand_no: good.matched_brandno,
          brand_name: good.brand_name,
          launch_date: good.launch_date,
          fta: good.fta === 'y' ? 'y' : 'n',
        };
        this.sampleGood = good;
        await this.calc('all');
        rows.push(JSON.parse(JSON.stringify({...good, ...this.sCalc})));
      }
      let baseFields = {
        shop: 'shop',
        shop_id: 'shop_id',
        category: 'category',
        goods_id: 'goods_id',
        origin_category: 'origin_category',
        name: 'name',
        brand_name: 'brand_name',
        color: 'color',
        launch_date: 'launch_date',
        'options.0.Size': '사이즈',
        'options.0.goods_consumer': 'goods_consumer',
        'options.0.goods_price': 'goods_price',
        'options.0.stock': '재고',
        crawl_url: 'crawl_url',
        price_unit: 'price_unit',
      };
      let dupFields = {
        basePrice: '기본현지가',
        localPrice: '세전현지가',
        adjustPrice: '조정현지가',
        standardPrice: '판매기준가',
        befTaxPriceWithoutDelivery: '세전KRW',
        localDeliveryCost: '해외배송비KRW',
        befTaxPrice: '배송비포함KRW',
        cTax: '관세',
        idvTax: '개소세',
        eduTax: '교육세',
        idvEduTax: '개소세교육세합',
        vatKrw: '부가세',
        vatPrice: '세후가격',
        rebatePrice: '판매수수료포함가격',
        pgFee: 'PG수수료',
        aftPgPrice: 'PG수수료포함가격',
        domestic_delivery_cost: '국내배송비',
        finalPrice: '국내가격',
        finalPriceRoundUp: '국내가격올림',
        finalPriceWithoutVatPg: 'VAT&PG제외가격',
      };
      let lastFields = {
        customs_rate: '관세율',
        vat: '부가세율',
        idvEduRate: '개소세교육세율',
        pgRate: 'PG수수료',
        rebate: '판매수수료KRW',
        befMargin: '수정전마진',
        margin: '수정마진',
        marginRate: '수정마진율',
        discountRate: '할인율',
        finalPrice: '최종판매가',
        finalPriceRoundUp: '최종판매가RoundUp',
        finalMargin: '최종마진',
        finalMarginRate: '최종마진율',
      };
      let fields = [Object.keys(baseFields).join(',')].concat('consumer,sale,supply'.split(',').map(e => Object.keys(dupFields).map(k => `${e}.${k}`).join(','))).concat(Object.keys(lastFields).join(',')).join(',');
      let header = [Object.values(baseFields).join(',')].concat('consumer,sale,supply'.split(',').map(e => Object.values(dupFields).map(k => `${e}_${k}`).join(','))).concat(Object.values(lastFields).join(',')).join(',');

      down(rows, header.split(','), fields.split(','), `Shop_${this.item.shop_id}_PriceSample200`);
    },
    jsonModal(title, json) {
      this.$modal.show({title, type: 'json', item: json});
    },
  }
}
</script>

<style scoped>
.simulate {
  text-align: right;
  background-color: #f2f2f2
}

.simulate.center {
  text-align: center;
}

.simulate .col:first-child {
  text-align: center;
}

.summary .row div {
  padding-top: 5px;
  padding-bottom: 5px;
}
.summary .row div:nth-child(1), .summary .row div:nth-child(3) {
  font-weight: bold;
  background-color: #f2f2f2;
}
.summary .row div:nth-child(2), .summary .row div:nth-child(4) {
  text-align: right;
}

</style>
