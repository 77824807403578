<template>
  <div>
    <div>
      <b-row class="mb-2">
        <b-col cols="6">
          <small>아이콘</small>
          <b-form>
            <b-form-file v-model="file.brandIcon" @change="handleFile($event, 'brandIcon')" ref="file" placeholder="이미지 업로드"></b-form-file>
          </b-form>
        </b-col>
        <b-col cols="6">
          <small>아이콘 확인</small>
          <div class="col-form-label" v-if="item.brandIcon && item.brandIcon.originalname">
            <a @click="$utils.open(`http://files.balaan.io/${item.brandIcon.key}`)" :title="item.brandIcon.originalname" class="pointer">
              <i class="fa fa-file-o"></i>
              {{ item.brandIcon.originalname }}
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="6">
          <small>메인 배너</small>
          <b-form>
            <b-form-file v-model="file.brandMainBanner" @change="handleFile($event, 'brandMainBanner')" ref="file" placeholder="이미지 업로드"></b-form-file>
          </b-form>
        </b-col>
        <b-col cols="6">
          <small>메인 배너 확인</small>
          <div class="col-form-label" v-if="item.brandMainBanner && item.brandMainBanner.originalname">
            <a @click="$utils.open(`http://files.balaan.io/${item.brandMainBanner.key}`)" :title="item.brandMainBanner.originalname" class="pointer">
              <i class="fa fa-file-o"></i>
              {{ item.brandMainBanner.originalname }}
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="6">
          <small>구매 시 필수 동의항목 설정</small>
          <b-form>
            <b-form-radio-group v-model="item.isMandatory" :options="[{text: '노출', value: true}, {text: '미노출', value: false}]"/>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mb-2" v-if="item.isMandatory">
        <b-col cols="12">
          <b-form>
            <b-form-input class="mb-2" v-model="item.mandatoryItemName" placeholder="발란몰 주문서 내 필수 동의항목으로 노출할 항목명을 입력해주세요." :maxlength="25"/>
            <b-textarea v-model="item.mandatoryItemContent" placeholder="발란몰 주문서 내 필수 동의항목으로 노출할 항목 내용을 입력해주세요."></b-textarea>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopBrandInfo',
  model: {prop: 'value', event: 'change'},
  props: {
    shop_id: Number,
    value: Object,
  },
  data() {
    return {
      busy: {upload: false},
      file: {brandIcon: null, brandMainBanner: null},
    }
  },
  created() {
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    async validateImgSize(img) {
      let _URL = window.URL || window.webkitURL;
      let objectUrl = _URL.createObjectURL(img);
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve({
          width : img.width,
          height : img.height
        })
        img.onerror = reject;
        img.src = objectUrl;
      })
    },
    async validateFiles(type, file) {
      if (!file) {
        return {ok: 0, msg: this.T`파일을 선택해주세요`};
      }
      const uploadableExts = 'png,jpg,jpeg'.split(',');
      if (!file.name.toLowerCase().split('.').pop().in(uploadableExts)) {
        return {ok: 0, msg: 'png, jpg, jpeg 확장자만 등록이 가능합니다. 파일을 확인해주세요.'};
      }
      if (type === 'brandIcon') {
        const {width, height} = await this.validateImgSize(file);
        if (+width > 160 || +height > 160) {
          return {ok: 0, msg: '이미지는 최대 160 X 160 사이즈까지 등록 가능합니다. 파일을 확인해주세요.'};
        }
      }
      if (file.size > 1024 * 1024) { // 파일 용량 제한 1MB
        return {ok: 0, msg: '첨부파일 용량은 최대 1MB까지 등록 가능합니다. 파일을 확인해주세요.'};
      }
      return {ok: 1};
    },
    async handleFile(event, type) {
      if (this.busy.upload) return;
      let file = [...(event.dataTransfer || event.target).files][0];
      const {ok, msg} = await this.validateFiles(type, file);
      if (!ok && msg) {
        return alert(msg);
      }

      this.busy.upload = true;
      const j = await this.$api.postForm('/shop/uploadFile', {file, shop_id: this.item.shop_id});
      this.busy.upload = false;

      if (j && j.ok) {
        let resFile = j.file;
        this.item[type] = {
          size: resFile.size,
          key: resFile.org.url.replace('https://files.balaan.io/', ''),
          originalname: file.name
        };
      }
      this.$forceUpdate();
    },
  }
}
</script>

<style>
</style>
